export const orderStatus = Object.freeze({
  /* предзаказ: ожидаем поступления товара */
  PRE_ORDER: 0,

  /* оформлен */
  CREATED: 1,

  /* ожидает проверки АОЗ */
  AWAITING_CHECK: 2,

  /* проверка АОЗ */
  CHECKING: 3,

  /* Ожидает подтверждения мерчантом */
  AWAITING_CONFIRMATION: 4,

  /* в обработке */
  IN_PROCESSING: 5,

  /* передан на доставку */
  TRANSFERRED_TO_DELIVERY: 6,

  /* в процессе доставки */
  DELIVERING: 7,

  /* находится в Пункте Выдачи */
  READY_FOR_RECIPIENT: 8,

  /* доставлен */
  DONE: 9,

  /* возвращен */
  RETURNED: 10,

  // реально НЕ СУЩЕСТВУЕТ в системе, вместо него флаг в ордере
  CANCELED: 100,
});

export const orderPaymentStatus = Object.freeze({
  /* Не оплачен */
  NOT_PAID: 1,

  /* Оплачен */
  PAID: 2,

  /* Просрочен */
  TIMEOUT: 3,

  /* Средства захолдированы */
  HOLD: 4,

  /* Ошибка */
  ERROR: 5,

  /* Ожидает оплаты */
  WAITING: 6,

  /* Оплата возвращена */
  RETURNED: 7,
});

export const sortFields = Object.freeze({
  NUMBER: 'number',
  CREATED_AT: 'created_at',
});

export const deliveryStatus = Object.freeze({
  // внутренние статусы [1, 20]
  /** создан */
  STATUS_CREATED: 1,

  // статусы доставки в случае "нормального" процесса доставки [21, 40]
  /** принят на склад в пункте отправления */
  STATUS_ON_POINT_IN: 21,
  /** в пути */
  STATUS_ON_WAY: 22,
  /** прибыл на склад в пункте назначения */
  STATUS_ON_POINT_OUT: 23,
  /** передана на доставку в пункте назначения */
  STATUS_DELIVERING: 24,
  /** готов к выдаче в пункте назначения */
  STATUS_READY_FOR_RECIPIENT: 25,
  /** доставлен получателю */
  STATUS_DONE: 26,

  // статусы по возвратам [41, 60]
  /** возвращен с доставки */
  STATUS_RETURNED_FROM_DELIVERY: 41,
  /** частичный возврат */
  STATUS_PARTIAL_RETURN: 42,
  /** подготовлен возврат */
  STATUS_RETURN_READY: 43,
  /** возвращается отправителю */
  STATUS_RETURNING: 44,
  /** возвращен отправителю */
  STATUS_RETURNED: 45,

  // проблемные и отмененные статусы [61, 80]
  /** утеряна */
  STATUS_LOST: 61,
  /** возникла проблема */
  STATUS_PROBLEM: 62,
  /** отменена */
  STATUS_CANCEL: 63,

  // нестандартные статусы [91, 100]
  /** неизвестный статус */
  STATUS_UNKNOWN: 91,
  /** n/a */
  STATUS_NA: 92,
});

export const filterField = Object.freeze({
  ALL_TIME: 'all_time',
  YEAR: 'year',
  MONTH: 'month',
  DAY: 'day',
});

export const cancelReasons = Object.freeze({
  // Нарушен срок доставки
  DELIVERY_VIOLATED: 1,

  // Передумал
  CHANGE_MIND: 2,

  // Нашел дешевле
  CHEAPER: 3,

  // Нашел доставку быстрее
  FASTER: 4,

  // Другое
  ANOTHER: 5,
});

export const paymentMethods = Object.freeze({
  google_pay: 'Google Pay',

  apple_pay: 'Apple Pay',

  visa: 'VISA',

  master_card: 'MasterCard',
});

export const orderType = Object.freeze({
  CERTIFICATE: 'certificate',
  MASTERCLASS: 'masterclass',
  PRODUCT: 'product',
});

export const orderTypeName = Object.freeze({
  [orderType.CERTIFICATE]: 'Сертификат',
  [orderType.MASTERCLASS]: 'Услуга',
  [orderType.PRODUCT]: 'Товар',
});


export const businessTypeId = Object.freeze({
  ACQUISITION: 1,
  ONGOING: 2,
  PROMO1: 3,
  PROMO2: 4,
  PROMO3: 5,
});

export const businessTypeName = Object.freeze({
  [businessTypeId.ACQUISITION]: 'Новый',
  [businessTypeId.ONGOING]: 'Обычный',
  [businessTypeId.PROMO1]: 'Промо - 1',
  [businessTypeId.PROMO2]: 'Промо - 2',
  [businessTypeId.PROMO3]: 'Промо - 3',
});
