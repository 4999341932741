
import {mapGetters, mapState} from 'vuex';
import env from '@/.env.json';
import Social from '@/components/elements/Social';
import FooterNav from '@/layouts/components/Footer/FooterNav';
import Logo from '~/components/elements/Logo';

export default {
  name: 'Footer',
  components: {
    Logo,
    Social,
    FooterNav,
  },
  data() {
    return {
      publicOffer: env.PUBLIC_OFFER,
      footerCopy: env.FOOTER_COPY,
      privacyPolicyLink: env.PRIVACY_POLICY,
      showSocialBlock: env.SHOW_SOCIAL_BLOCK,
    }
  },
  computed: {
    ...mapState({ logo: 'logo' }),
    ...mapGetters({
      menuFooter: 'getMenuFooter',
    }),
    year() {
      const today = new Date();
      return today.getFullYear();
    },
    mqIsMobile() {
      return this.$mq === 'mobileXs' || this.$mq === 'mobile' || this.$mq === 'tablet';
    },
    isMainPage() {
      return this.$route.name === 'main';
    },
  },
};
